import React, { useContext, useState, useEffect } from "react";
import { Context } from "./components/context/Store";

export const ModalContext = React.createContext();

export const ModalProvider = ({ children }) => {
    const { state, dispatch } = useContext(Context);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
		let general = localStorage.getItem("general");
        general = JSON.parse(general);
        dispatch({ type: "UPDATE_SESSION_DATA", general: general });

		if(state?.general?.session === false){
			setIsError(true);
		}else{
			setIsError(false);
		}
	}, [state?.general?.session]);

    return (
        <ModalContext.Provider
            value={{
                isError,
                setIsError,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};
