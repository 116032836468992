const ChiefReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            const user_data = { ...state.user_data, ...action.user_data };
            localStorage.setItem("user_data", JSON.stringify(user_data));
            return {
                ...state,
                user_data: user_data,
            };
        case "UPDATE_ELEARN_DATA":
            const elearn_data = { ...state.elearn_data, ...action.elearn_data };
            localStorage.setItem("elearn_data", JSON.stringify(elearn_data));
            return {
                ...state,
                elearn_data: elearn_data,
            };
        case "UPDATE_SUBSCRIPTION_DATA":
            const user_subscription = { ...state.user_subscription, ...action.user_subscription };
            localStorage.setItem("user_subscription", JSON.stringify(user_subscription));
            return {
                ...state,
                user_subscription: user_subscription,
            };
        case "UPDATE_SESSION_DATA":
            const general = { ...state.general, ...action.general };
            localStorage.setItem("general", JSON.stringify(general));
            return {
                ...state,
                general: general,
            };
    }
}

export default ChiefReducer;
