import React, { useContext, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faCircleExclamation, faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/general/helpers/positioned-loader";
import { Context } from "../../components/context/Store";
import { ModalContext } from "../../ModalContext";

function PopUp({ setLogOutPopUp, setLogOutPopUpBinary }) {
    const { state, dispatch } = useContext(Context);
    const [ isLoading, setIsLoading ] = useState(false);
    const { isError, setIsError } = useContext(ModalContext);

    const navigate = useNavigate();

    const handleNavigation = (navLocation) => {
        setIsLoading(true)
        dispatch({
            type: 'UPDATE_SESSION_DATA',
            general: {
                session: ""
            }
        })
        window.localStorage.clear();

        window.location.replace = navLocation;
        setTimeout(() => {
            // setLogOutPopUpBinary(0);
            // setLogOutPopUp(false);
            setIsLoading(false)
        }, 1000);
    }

    return ( 
        // isLoading ? <Loader /> :
        <div className="pop-up-box">
            <div className="pop-up-box-child">
                <FontAwesomeIcon icon={faClose} className="pop-up-box-close" onClick={() => handleNavigation('/')} />
                <div>
                    <FontAwesomeIcon icon={faCircleExclamation} className="pop-up-box-icon" />
                    <h3>Your account got logged out!</h3>
                    <p>It looks like you've been logged out of WiseTalkies because your account is currently active on too many devices. Please login to continue.</p>
                </div>
                <Button className="pop-up-box-button" onClick={() => handleNavigation('/join')}>
                    Login
                </Button>
            </div>
        </div>
    );
}

export default PopUp;
