import axios from "axios";
import dayjs from "dayjs";

axios.defaults.withCredentials = true;

let isTokenRefreshing = false;
let refreshSubscribers = [];

const handleTokenExpiry = (tokenExpiry) => {
    const isTokenExpiringSoon = dayjs.unix(tokenExpiry).diff(dayjs(), 'second') < 1;
    return isTokenExpiringSoon;
}

var promiseResolve, promise;

const axiosInstance = ( url ) => {
    const instance = axios.create({
        baseURL: url,
    });
    
    instance.interceptors.request.use(async (config) => {
        // if (config.baseURL.includes(process.env.REACT_APP_ACCOUNTS_URL_SUBSTRING)) {
        //     try {
        //       const response = await axios.get(`${process.env.REACT_APP_API_URL_ACCOUNTS}api/v2/users/csrf`, {
        //         headers: { 'Authorization': null },
        //         credentials: 'same-origin'
        //       });
        
        //       if (response.status === 200) {
        //         config.headers["X-CSRFToken"] = response.headers["x-csrftoken"];
        //       }
        //     } catch (error) {
        //     //   console.error('Error fetching CSRF token:', error);
        //     }
        // }

        if(isTokenRefreshing){
            await promise;
        }
        
        let csrfToken = "";
        let user_data = localStorage.getItem("user_data");
        user_data = JSON.parse(user_data);
        let accessToken = user_data?.accessToken;
        let tokenExpiry = user_data?.tokenExpiry;
        let isTokenExpiringSoon = "";
        
        isTokenExpiringSoon = handleTokenExpiry(tokenExpiry);
      
        if (accessToken && isTokenExpiringSoon) {
            isTokenRefreshing = true;

            promise = new Promise(function(resolve, reject){
                promiseResolve = resolve;
            });

            await axios
                .get(`${process.env.REACT_APP_API_URL_ACCOUNTS}api/v2/users/csrf`, {
                    headers: { 'Authorization': null },
                    credentials: 'same-origin'
                })
                .then(async (response) => {
                    if (response.status === 200) {
                        csrfToken = response.headers['x-csrftoken']
                    } else {
                        return;
                    }
        
                    try {
                        const tokenRefreshResponse = await axios.post(`${process.env.REACT_APP_API_URL_ACCOUNTS}api/v2/users/token-refresh`, {}, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                'X-CSRFToken': csrfToken
                            },
                            credentials: "include"
                        });
    
    
                        const updated_user_data = {
                            firstName: user_data.firstName,
                            lastName: user_data.lastName,
                            isSignup: user_data.isSignup,
                            userId: user_data.userId,

                            isSubscribed: user_data.isSubscribed,
                            profilePicture: user_data?.profilePicture,
                            subscriptionExpiry: user_data?.subscriptionExpiry,
                            accessToken: tokenRefreshResponse?.data?.access_token,
                            tokenExpiry: tokenRefreshResponse?.data?.expire_at
                        };
    
    
                        localStorage.setItem("user_data", JSON.stringify(updated_user_data));
    
                        config.headers.Authorization = `Bearer ${tokenRefreshResponse?.data?.access_token}`;
                        
                        refreshSubscribers.forEach((subscriber) => subscriber(tokenRefreshResponse?.data?.access_token));
                        refreshSubscribers = [];
                        isTokenRefreshing = false;
                        promiseResolve();

                        return config;

                    } catch (error) {
                        // if(error.response.status === 403){

                        //     isTokenExpiringSoon = false;
                        //     window.localStorage.clear();
                        //     let updatedLoginData = {
                        //         session: false,
                        //     };
                        //     localStorage.setItem("general", JSON.stringify(updatedLoginData));
                        //     window.location.replace('/join')
                        // }else 
                        if (error.response.status === 503) {
                            window.location.replace('/maintenance')
                        }
                        return Promise.reject(error);
                    }

                })
                .catch((error) =>{
                    if (error.response.status === 503) {
                        window.location.replace('/maintenance')
                    }
                })
        }else {

            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
        }   
    );

    instance.interceptors.response.use(
        async (response) => {
            return response;
        },
        async (error) => {
            if(isTokenRefreshing){
                await promise;
            }
            let csrfToken = "";
            let user_data = localStorage.getItem("user_data");
            user_data = JSON.parse(user_data);
            let accessToken = user_data?.accessToken;
            let tokenExpiry = user_data?.tokenExpiry;
            let isTokenExpiringSoon = "";

            if (error.response.status === 401) {
                axios
                    .get(`${process.env.REACT_APP_API_URL_ACCOUNTS}api/v2/users/session`)
                    .then(async (response) => {
                        if(response.data.isAuthenticated) {
                            isTokenRefreshing = true;

                            promise = new Promise(function(resolve, reject){
                                promiseResolve = resolve;
                            });
                
                            await axios
                                .get(`${process.env.REACT_APP_API_URL_ACCOUNTS}api/v2/users/csrf`, {
                                    headers: { 'Authorization': null },
                                    credentials: 'same-origin'
                                })
                                .then(async (response) => {
                                    if (response.status === 200) {
                                        csrfToken = response.headers['x-csrftoken']
                                    } else {
                                        return;
                                    }
                        
                                    try {
                                        const tokenRefreshResponse = await axios.post(`${process.env.REACT_APP_API_URL_ACCOUNTS}api/v2/users/token-refresh`, {}, {
                                            method: "POST",
                                            headers: {
                                                "Content-Type": "application/json",
                                                'X-CSRFToken': csrfToken
                                            },
                                            credentials: "include"
                                        });
                    
                    
                                        const updated_user_data = {
                                            firstName: user_data.firstName,
                                            lastName: user_data.lastName,
                                            isSignup: user_data.isSignup,
                                            userId: user_data.userId,
                
                                            isSubscribed: user_data.isSubscribed,
                                            profilePicture: user_data?.profilePicture,
                                            subscriptionExpiry: user_data?.subscriptionExpiry,
                                            accessToken: tokenRefreshResponse?.data?.access_token,
                                            tokenExpiry: tokenRefreshResponse?.data?.expire_at
                                        };
                    
                    
                                        localStorage.setItem("user_data", JSON.stringify(updated_user_data));
                    
                                        error.config.headers.Authorization = `Bearer ${tokenRefreshResponse?.data?.access_token}`;
                                        
                                        refreshSubscribers.forEach((subscriber) => subscriber(tokenRefreshResponse?.data?.access_token));
                                        refreshSubscribers = [];
                                        isTokenRefreshing = false;
                                        promiseResolve();
                
                                        return error.config;
                
                                    } catch (error) {
                                        if(error.response.status === 403){
                
                                            isTokenExpiringSoon = false;
                                            window.localStorage.clear();
                                            let updatedLoginData = {
                                                session: false,
                                            };
                                            localStorage.setItem("general", JSON.stringify(updatedLoginData));
                                            window.location.replace('/join')
                                        }else if (error.response.status === 503) {
                                            window.location.replace('/maintenance')
                                        }
                                        return Promise.reject(error);
                                    }
                
                                })
                                .catch((error) =>{
                                    if (error.response.status === 503) {
                                        window.location.replace('/maintenance')
                                    }
                                })
                        }else{
                            window.localStorage.clear();
                            let updatedLoginData = {
                                session: false,
                            };
                            localStorage.setItem("general", JSON.stringify(updatedLoginData));
                            window.location.replace('/join')
                        }
                    })
                    .catch((error) =>{
                        if (error.response.status === 503) {
                            window.location.replace('/maintenance')
                        }
                    })

                
            }
            else if (error.response.status === 503) {
                window.location.replace('/maintenance')
            }
            return Promise.reject(error);
        },
    )

    return instance;
}

export const Payments = axiosInstance(process.env.REACT_APP_API_URL_PAYMENTS)
export const Activity = axiosInstance(process.env.REACT_APP_API_URL_ACTIVITY)
export const Elearn = axiosInstance(process.env.REACT_APP_API_URL_ELEARN)
export const Accounts = axiosInstance(process.env.REACT_APP_API_URL_ACCOUNTS)
