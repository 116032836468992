import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "./Store";
import dayjs from "dayjs";
import PageLoader from "../general/helpers/page-loader";
import { Accounts } from "../../axiosConfig";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
	const { state, dispatch } = useContext(Context);
	const accessToken = state.user_data.accessToken;
	const [authTokens, setAuthTokens] = useState(() => localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : null);
	const [loading, setLoading] = useState(true);
	
	let isTokenExpiringSoon = ""

	useEffect(() => {
		if(loading){
			setLoading(false);
		}
	}, [])

	return (
		<AuthContext.Provider
			value={{
				authTokens,
				setAuthTokens,
			}}
		>
			{loading ? <PageLoader /> : children}
		</AuthContext.Provider>
	);
};
