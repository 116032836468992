import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/Store";
import { formatRemainingTime } from "../helpers/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const BottomBanner = ({setShowModal, showBanner, setShowBanner, contestApplied, campaignData}) => {
    const navigate = useNavigate();
    const { state } = useContext(Context);
    const [timeLeft, setTimeLeft] = useState("");

    useEffect(() => {
        if (campaignData?.campaign_end_date) {
            const endDate = campaignData.campaign_end_date;

            // Function to update the time remaining
            const updateRemainingTime = () => {
                const formattedTime = formatRemainingTime(endDate);
                setTimeLeft(formattedTime);
            };

            // Call the function immediately to set the initial value
            updateRemainingTime();

            // Set up an interval to update the remaining time every second
            const interval = setInterval(updateRemainingTime, 1000);

            // Clear interval on unmount
            return () => clearInterval(interval);
        }
    }, [campaignData?.campaign_end_date]);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isAtBottom = scrollPosition + window.innerHeight >= document.body.scrollHeight - 5;

    return (
        <div className={`bottom-banner ${showBanner && "slideup"} ${isAtBottom ? 'slide-up' : 'slide-down'}`}>
            <div className="offer">
                Take part in <strong>{campaignData?.campaign_name}.</strong> Win a{" "}
                <span className="">Camcordy 4K Vlogging Camera!</span>
            </div>
            <div className="time-container">
                <div className="">Remaining Time:<span className="timer">{timeLeft}</span></div>
                <div className="email-input">
                    {
                        contestApplied ?
                        <span className="button-static">Contest Applied</span>
                        :
                        <span 
                            className="button"
                            onClick={(e) => {
                            e.preventDefault();
                            setShowModal(true);
                            // if(state.user_data.accessToken){
                            // }else{
                            //     navigate("/join")
                            // }
                        }}>{contestApplied ? "Contest Applied" : "Participate!"}</span>

                    }
                </div>
            </div>
            {/* <div className="close-btn slider-handle">
                <FontAwesomeIcon icon={faClose} onClick={() => setShowBanner(!showBanner)} />
            </div> */}
        </div>
    );
};

export default BottomBanner;
