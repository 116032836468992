import React from "react";
import Lottie from "lottie-react";

import loader from "../../../assets/lotties/contest-success.json";

export default function ContestSuccess() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loader,
		rendererSettings: {},
	};
	return (
		<div className="success-loader">
			<Lottie animationData={loader} options={defaultOptions} style={{ height: 150, width: 150 }} />
		</div>
	);
}
