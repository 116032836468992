import React from "react";
// import Lottie from "react-lottie";
import Lottie from "lottie-react";
import animationData from "../../../assets/lotties/globe-loader.json";

export default function Loader() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {},
	};
	return (
		<div className="page-loader">
			<Lottie animationData={animationData} options={defaultOptions} style={{ height: 150, width: 150 }} />
		</div>
	);
}
