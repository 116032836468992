import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.scss';
import './assets/css/responsive.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'
// import Routers from "./routers";
// import { useLocation, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import MainRouter from './components/routing/routers/MainRouter';
import "react-datepicker/dist/react-datepicker.css";

import Store from './components/context/Store';
import AuthRouters from './utils/AuthRouters';
import { Accounts } from './axiosConfig';
import Notification from './Notification';
//const Routers = React.lazy(()=> import('./routers'));

function App() {

	useEffect(() => {
		document.cookie = "";
	},[])
	
	return (
		<Store>
			{/* <Routers /> */}
			{/* <Router>
				<MainRouter />
			</Router> */}
			<AuthRouters />
			{/* <Notification /> */}
		</Store>
	);
}

export default App;
