import React from "react";
import Lottie from "lottie-react";
// import Lottie from "react-lottie";

import loader from "../../../assets/lotties/search-loader.json";

export default function SearchLoader() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loader,
		rendererSettings: {},
	};
	return (
		<div className="button-loader">
			<Lottie animationData={loader} options={defaultOptions} style={{ height: 30, width: 30 }} />
		</div>
	);
}
