import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
const WebsiteLayout = React.lazy(()=> import('../components/website-layout'));

const NotFound = () => {
    const navigate = useNavigate();

    return(
        // <WebsiteLayout>
            <section className="found-box text-center">
                <Container>
                    <Row>
                        <Col lg={12} md={12}>
                            {/* <h1 className="found-title">Ooops... Page not found</h1> */}
                            <div className="error-image-container">
                                <img src={require("../assets/images/wise-talkies-logo-square.png")} alt="Logo with 404" />
                            </div>
                            <p className="found-txt">Oops, this page doesn’t exist</p>
                            <p className="go-back-txt">Go back to the homepage to learn more.</p>
                            <Button onClick={() => navigate('/')} className='button-effect-primary'>WISETALKIES.COM</Button>
                            {/* <Button className="found-search-btn">Search <FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                            <Button className="go-back-btn">Go Back <FontAwesomeIcon icon={faArrowRight} /></Button> */}
                        </Col>
                    </Row>
                </Container>
            </section>
        // </WebsiteLayout>
    )
}

export default NotFound;