import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user_data: {
        accessToken: "",
        userId: "",
        profilePicture: "",
        subscriptionExpiry: "",
        tokenExpiry: "",
        isSignup: false,
        firstName: "",
        lastName: ""
    },
    elearn_data: {
        activeCategory: "",
        activeCourse: "",
        activeTopic: "",
        activeLesson: "",
        activeInstructor: "",
    },
    user_subscription: {
        expiringOn: "",
        isSubscribed: false,
        planId: "",
        plan: "",
        price: "",
        orderId: ""
    },
    general: {
        session: "",
        prevPath: "/"
    },
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(initialState);

export default Store;
