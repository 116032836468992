export const getDateStr = (value) => {
    let date = new Date(value);
    let month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ][date.getMonth()];
    let date_str = date.getDate() + " " + month + " " + date.getFullYear();
    return date_str;
};

export const getDDMMYY = (value) => {
    if (value) {
        var today = new Date(value);
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        today = dd + "-" + mm + "-" + yyyy;
        return today;
    }
    return "";
};

export const getYYMMDD = (value) => {
    if (value) {
        var today = new Date(value);
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + "-" + mm + "-" + dd;
        return today;
    }
    return "";
};

// export const getTimeStr = (value) => {
//     if (value) {
//         let values = value.split(":");
//         let hr = values[0];
//         let min = values[1];
//         let ampm = values[2].split(" ")[1];
//         let time_str = "";
//         if(hr != "00"){
//             time_str = hr + " hr " + min + " min";
//         }else{
//             time_str = min + " min";
//         }
//         return time_str;
//     } else {
//         return null;
//     }
// };

export const getTimeStr = (value) => {
    if (value) {
        let values = value.split(":");
        let hr = parseInt(values[0], 10).toString(); // Convert to integer and remove leading zero
        let min =  parseInt(values[1], 10).toString();
        let time_str = "";
        console.log(hr, `"inside hr !== "00" && min !== "00""`, min)
        if (hr !== "0" && min !== "0") {
            console.log(`"inside hr !== "00" && min !== "00""`)
            time_str = hr + " hr " + min + " min";
        } else if(min == "0"){
            console.log(`"inside min !== "00"`)
            time_str = hr + " hour";
        } else {
            console.log(`"inside else`)
            time_str = min + " minutes";
        }
        return time_str;
    } else {
        return null;
    }
};

export const getTime = (value) => {
    if (value) {
        let values = value.split(" ");
        let time = values[1].split(":");
        let hr = time[0];
        let min = time[1];
        let time_value = hr + ":" + min;
        return time_value;
    } else {
        return null;
    }
};
export const timeDuration = (value) => {
    if (value) {
        let time = value.split(":");
        let hr = time[0];
        let min = time[1];
        let sec = time[2];
        let time_value = "";
        if(hr == '00'){
            time_value = min + ":" + sec
        }else{
            time_value = hr + ":" + min + ":" + sec
        }
        return time_value;
    } else {
        return null;
    }
};

export const toConvert = (time, type) => {
    if (time) {
        // Check correct time format and split into components
        time = time
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            if (type === "hm") {
                time.pop();
            }
            time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }

        return time.join(""); // return adjusted time or original string
    } else {
        return null;
    }
}

export const ISTstamp = (value) => {
    var dateUTC = new Date(value);
    // console.log(dateUTC,"dateUTC 1 from function");
    var dateUTC = dateUTC.getTime();
    // console.log(dateUTC,"dateUTC 2 from function");
    var dateIST = new Date(dateUTC);
    // console.log(dateIST,"dateIST from function");
    //date shifting for IST timezone (+5 hours and 30 minutes)
    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);
    return dateIST;
};

export function convertUnixTimestamp(timestamp) {
    // Convert Unix timestamp to milliseconds
    const dateObj = new Date(timestamp * 1000);
  
    // Define options for date formatting
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZoneName: "short"
    };
  
    // Format the date and time according to the options
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObj
    );
  
    // Return the formatted date
    return formattedDate;
}

export function timeConverter (timeValue) {
    let time = timeValue
    let [minutes, seconds] = time.split(":");
    let totalSeconds = Math.round(Number(minutes) * 60 + Number(seconds));
    return totalSeconds;
}

export function secondsTimeConverter(totalSeconds) {
    let hours = Math.floor(totalSeconds / 3600);
    let remainingSeconds = totalSeconds % 3600;
    let minutes = Math.floor(remainingSeconds / 60);
    let seconds = remainingSeconds % 60;

    if(hours){
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }else{
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    
}

export function daysToMonths(days) {
    const daysInAverageMonth = 30;
    const months = days / daysInAverageMonth;
    return months;
}

export function cardType(value) {
    if (value) {
        let card = value.split("_");
        let fname = card[0];
        let lname = card[1];
      
        return fname + " " + lname;
    } else {
        return null;
    }
}
export const formatRemainingTime = (endDate) => {
    const now = new Date(); // Get the current date and time
    const end = new Date(endDate); // Convert endDate to a Date object
    const timeDiff = Math.floor((end - now) / 1000); // Calculate the difference in seconds

    // If the difference is negative, the end date is in the past
    if (timeDiff <= 0) {
        return "Time's up!";
    }

    const days = Math.floor(timeDiff / (24 * 60 * 60)); // Calculate remaining days
    const hours = Math.floor((timeDiff % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((timeDiff % (60 * 60)) / 60);
    const seconds = timeDiff % 60;

    // If time is more than 24 hours, show days along with time
    if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} left`;
    } else {
        // Otherwise, show HH:MM:SS
        return `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
};