import React, { Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { AuthProvider } from "../components/context/AuthContext2";
import { Context } from "../components/context/Store";
import HomePageRouter from "../components/routing/routers/HomeRouter";
import { Accounts } from "../axiosConfig";
import { ModalProvider } from "../ModalContext";
import PageLoader from "../components/general/helpers/page-loader";
// import MainRouter from "../components/routing/routers1/MainRouter";
// import Maintenance from "../components/general/auth-hoc/Maintenance";
// import Footer from "../components/footer";
// import Routers from "../routers";
// import WebsiteLayout from "../components/website-layout";
// import PageLoader from "../components/general/helpers/page-loader";
// import NotFound from "../pages/notfound";

const lazyDelayed = (importPromise) => {
    return React.lazy(() =>
        Promise.all([
            importPromise,
            new Promise((resolve) => setTimeout(resolve, 300)), // ensures minimal delay
        ]).then(([module]) => module)
    );
};

// const HomePage = lazyDelayed(import('../pages/index'));
// const Login = lazyDelayed(import('../pages/loginpage'));
// const Header = lazyDelayed(import('../components/header'));
const AppRouter = lazyDelayed(import('../components/routing/routers/AppRouter'));
const MainRouter = lazyDelayed(import('../components/routing/routers1/MainRouter'));
const Maintenance = lazyDelayed(import('../components/general/auth-hoc/Maintenance'));
const Checkout = lazyDelayed(import('../components/payment/checkout'));

// Pages in which <WebsiteLayout> needs to have a prop admin={true}
const adminPaths = [
    "/bookmarks",
    "/referrals",
    "/play-list",
    "/bookmarks",
    "/browse-trailers",
    "/gift-card",
    "/playlist",
    "/articles-details",
    "/articles",
    "/discussion",
    "/discussion-details",
    "/communities",
    "/settings",
    "/course-completion",
    "/case-study",
    "/instructor",
    "/topic-quiz",
    "/topic-quiz-completion",
    "/course-details",
    "/nonmember",
    "/member",
    "/course-detail",
    "/my-progress",
    "/test",
];

function AuthRouters() {
    const [scroll, setScroll] = useState(false)
	const [isLoading, setLoading] = useState(true);
	const {
		dispatch,
		state,
	} = useContext(Context);
    // const accessToken = user_data.accessToken
        
    // let isAdmin = adminPaths.includes(window.location.pathname);

    let isLogin = ""

	// const baseURL = "http://localhost:8009/";
    
    // const handleCSRF = () => {
    //         fetch(`${baseURL}api/v2/users/csrf`,{
    //             credentials : "include"
    //             }
    //         )
    //     // customAxios
    //     //     .get(`${baseURL}api/v2/users/csrf`, {
    //     //         // headers: { 'Authorization': null },
    //     //         credentials : "include"
    //     //         }
    //     //     )
    //         .then((response) => {
    //             // // console.log(response,"_________CSRF RESPONSE____________", response.headers.get('X-CSRFToken'));
    //             // const cookieValue = document.cookie.split('=')[1];
    //             // // console.log("CSRF cookieValue++++++++", cookieValue);
    //             // setCsrfToken(cookieValue)
    //             // setCsrfToken(response.headers.get('X-CSRFToken'))
    //             dispatch({
    //                 type: "UPDATE_USER_DATA",
    //                 user_data: {
    //                     csrfToken: response.headers.get('X-CSRFToken')
    //                 }
    //             })
    //         })
    //         .catch( (error) => {
    //             // alert('Error message.');
    //             // // console.log(error,"ERROR FROM CSRF RESPONSE+++++++");
    //         });
    // };
    
	useEffect(() => {
        let user_data = localStorage.getItem("user_data");
		user_data = JSON.parse(user_data);
		dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });
		setTimeout(() => setLoading(false), 1100)


        // handleCSRF();
	}, []);


	isLogin = state?.user_data?.accessToken ? true : false;

    return (
        <Suspense fallback={<PageLoader />}>
            <Router>
                <div className="flex flex-col min-h-screen">
                {/* <div className="flex flex-col min-h-screen overflow-hidden"> */}
                    <AuthProvider>
                        <ModalProvider>
                            {/* <Header />
                            <Routes>
                                <Route element={<HomePage />} path="/" />
                                <Route element={<HomePage />} path="/home" />
                                <Route element={
                                    <PrivateRoute>
                                        <AppRouter />
                                    </PrivateRoute>
                                } path="/" />
                                <Route element={<Login />} path="/join" />
                            </Routes>
                            <Footer /> */}
                            
                            <Routes>
                                {/* <Route path="/*" element={
                                    <AuthRoute>
                                        <AuthRouter
                                            setScroll={setScroll}
                                            scroll={scroll}
                                        />
                                    </AuthRoute>
                                } />
                                <Route path="home/*" element={<PrivateRoute>
                                    <AppRouter
                                        setScroll={setScroll}
                                        scroll={scroll}
                                    />
                                </PrivateRoute>} />*/}
                                {/* <Route path="/web/*" element={<WebRouter />} /> */}
                                {/* <Route path='/checkout' element={<Checkout />} /> */}
                                <Route path='/maintenance' element={<Maintenance />} />
                                <Route path='/*' element={<MainRouter />} />
                            </Routes>
                        </ModalProvider>
                    </AuthProvider>
                </div>
            </Router>
        </Suspense>
    );
}

export default AuthRouters;
