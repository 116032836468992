import React, { useContext, useState } from "react";
// import { X, Camera } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Input, Modal, Label, ModalBody } from "reactstrap";
import { Button } from "react-bootstrap";

import Lock from "../../../assets/images/numbers/lock.svg";
import Camera from "../../../assets/images/numbers/camera.svg";
import One from "../../../assets/images/numbers/oneNumber.svg";
import Two from "../../../assets/images/numbers/twoNumber.svg";
import Three from "../../../assets/images/numbers/threeNumber.svg";
import Winner from "../../../assets/images/numbers/winner.svg";
import { Context } from "../../context/Store";
import { useNavigate } from "react-router-dom";
import { Accounts } from "../../../axiosConfig";
import ContestSuccess from "../helpers/contest-success";
import { getDateStr } from "../helpers/functions";

const PromotionalPopup = ({ isOpen, onClose, campaignData, setContestApplied }) => {
    const [isToggled, setIsToggled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [code, setCode] = useState("");
    
    const navigate = useNavigate();

    const { state } = useContext(Context);

    const accessToken = state.user_data.accessToken;

    const handleCheckEligibility = async () => {
        if(code){
            await Accounts.post(
                `api/v2/general/participate-contest`,{
                    code: code,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
                .then((response) => {
                    console.log(response, "api/v2/general/participate-contest");
                    if (response.status == 200) {
                        setIsLoading(true);
                        setContestApplied(true);
                        setTimeout(() => {
                            onClose();
                        }, 1500);
                    }
                })
                .catch((error) => {
                    // console.log(error);
                });
        }else{
            setError("Please provide a code")
        }
    };

    const toggle = () => {
        if (accessToken) {
            setIsToggled(true);
        } else {
            onClose();
            navigate("/join");
        }
    };

    const handleSubmit = () => {
        console.log("handleSubmit");
    };
    if (!isOpen) return null;

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
                setIsToggled(false);
            }}
            className="promotional-modal"
            backdrop={true}
        >
            <ModalBody>
                <div className="main-container ">
                    <div className="close-btn">
                        <FontAwesomeIcon icon={faClose} onClick={() => {
                            onClose();
                            setIsToggled(false);
                        }} />
                    </div>
                    {
                    isLoading ?
                    <div>
                        <ContestSuccess />
                        <p className="text-center">Successfully submitted the code.</p>
                    </div>
                    :
                    isToggled ? (
                        <div className="text-center">
                            <span className="cam-image-container">
                                <img src={Lock} alt="Lock" />
                            </span>
                            <h2 className="text-2xl font-bold mb-2">
                                Enter the Hidden Code
                            </h2>
                            <p className="text-gray-600 mb-4">
                                Found the code? Great! Enter it below to
                                continue.
                            </p>

                            <form className="space-y-4">
                                <div>
                                    <input
                                        type="text"
                                        value={code}
                                        onChange={(e) =>{
                                            setCode(e.target.value);
                                            setError("")
                                        }}
                                        placeholder="Enter the secret code"
                                        className="secret-code px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        // maxLength={6}
                                    />
                                    <div className="relative">
                                        {error && (
                                            <p className="danger">
                                                {error}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <span
                                    className="btn btn-branded"
                                    onClick={() => handleCheckEligibility()}
                                >
                                    Submit Code
                                </span>
                            </form>

                            <p className="text-sm text-gray-500 mt-4">
                                *Make sure you've entered the code correctly.
                                It's case-sensitive!
                            </p>
                        </div>
                    ) : (
                        <div className="text-center">
                            <span className="cam-image-container">
                                <img src={Camera} alt="Camera" />
                            </span>
                            <h2 className="text-2xl font-bold mb-2">
                                Win a Camcordy 4K Vlogging Camera!
                            </h2>
                            <p className="text-gray-600 mb-4">
                                Celebrate the Wisetalkies platform launch and
                                take part in the #CodeChallenge
                            </p>

                            <ul className="text-left text-gray-700 mb-6 space-y-2">
                                <li>
                                    <span className="image-container">
                                        <img src={One} alt="One" />
                                    </span>
                                    Find the hidden code in our social media posts.
                                </li>
                                <li>
                                    <span className="image-container">
                                        <img src={Two} alt="Two" />
                                    </span>
                                    Enter the code at wisetalkies.com/signup.
                                </li>
                                <li>
                                    <span className="image-container">
                                        <img src={Three} alt="Three" />
                                    </span>
                                    Share the post and tag a friend.
                                </li>
                            </ul>

                            <p className="text-sm text-gray-500 mb-4">
                                <span className="win-image-container">
                                    <img src={Winner} alt="Winner" />
                                </span>{" "}
                                Winner will be announced on{" "}
                                <span className="winner-date">{getDateStr(campaignData?.campaign_end_date)}</span>
                            </p>
                            <span className="btn btn-branded" onClick={toggle}>
                                Enter Now
                            </span>
                        </div>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default PromotionalPopup;
