import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../../pages/notfound";
import SectionLoader from "../../general/helpers/loader";
import Header from "../../header";
import WebsiteLayout from "../../website-layout";
// import PrivateRoute from "../routes/PrivateRoute";

const HomePage = lazy(() => import("../../../pages/index"));


const lazyDelayed = (importPromise) => {
    return React.lazy(() =>
    Promise.all([
        importPromise,
        new Promise((resolve) => setTimeout(resolve, 300)), // ensures minimal delay
    ]).then(([module]) => module)
    );
};

const Category = lazyDelayed(import("../../../pages/category"));
const CourseLanding = lazyDelayed(import("../../../pages/course-landing"));
const Faq = lazyDelayed(import("../../../pages/faqpage"));
const ContactUs = lazyDelayed(import("../../../pages/contactus"));
const AboutUs = lazyDelayed(import("../../../pages/AboutUs"));
const Communities = lazyDelayed(import("../../../pages/communities-page"));
const Articles = lazyDelayed(import("../../../pages/articles-page"));
const LoginPg = lazyDelayed(import("../../../pages/loginpage"));
const MemberShip = lazyDelayed(import("../../../pages/membership"));
const Instructor = lazyDelayed(import("../../../pages/instructor-page"));
const PrivateRoute = lazyDelayed(import("../routes/PrivateRoute"));


function HomePageRouter({ scroll, setScroll }) {

    return (
        <WebsiteLayout >
            <Suspense fallback={<SectionLoader />}>
                <Routes>
                    {/* <Route path='/' element={<Header />}> */}
                    <Route path="/" element={<HomePage />} />
                    {/* <Route path="/home" element={<HomePage />} /> */}
                    <Route path='/category/:cat_id' element={<Category />} />
                    <Route path='/courses/:course_id' element={<CourseLanding />} />
                    <Route path='/membership' element={<MemberShip />} />
                    <Route path='/join' element={<LoginPg/>} />
                    {/* <Route path='/articles' element={<Articles />} />
                    <Route path='/communities' element={<Communities />} /> */}
                    {/* <Route path='/about' element={<AboutUs />} /> */}
                    <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/contact-us' element={<ContactUs />} />
                    <Route path='/faq' element={<Faq />} />
                    <Route path='/instructor' element={<Instructor />} />
                    {/* <Route path="*" element={<NotFound />} /> */}
                    <Route path="*" element={<PrivateRoute />} />

                    {/* </Route> */}
                </Routes>
            </Suspense>
        </WebsiteLayout>
    );
}

export default HomePageRouter;
